import axios from "axios";
import { IUserConfigurationParameters } from "models/UserConfigurationParameters";

interface IPortalService {
  getUserConfigurationParameters(): Promise<IUserConfigurationParameters>;
}

export const PortalService: IPortalService = {
  getUserConfigurationParameters: async (): Promise<IUserConfigurationParameters> => {
    const { data } = await axios.get(
      `/api/configurationParameters`
    );
    return data;
  },
};
